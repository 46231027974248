import React from 'react'
import { Link } from 'gatsby'

const WrightsonMenu = (props) => (
  <div>
  <h2 className="major">Bernie Wrightson</h2>
  <ul className="bigList">
  <li className={props.pageName === 'frankenstein' ? 'current' : ''}><Link to="/clients/wrightson/frankenstein">Cycle of the Werewolf</Link></li>
  </ul>
  </div>
)

export default WrightsonMenu
