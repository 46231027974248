import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import WrightsonMenu from './_menu.js'

const WrightsonFrankensteinPage = ({ data }) => (
  <Layout bgClass="clients-wrightson">
    <WrightsonMenu pageName="frankenstein" />
    <div className="textPageDetails">
      <p>
        Nakatomi had a long time association with legendary illustrator Bernie Wrightson, publishing several special projects from his long career. Projects included large format screenprints of his classic "Frankenstein Portfolio", a letter-press edition of his art from Stephen King's <i>The Stand</i>, and a deluxe box set of Wrightson's <i>Cycle of the Werewolf</i> illustrations, including a hand-made wood box with a laser-etched lid.
      </p>
    </div>
    <div className="gallery-container">
      <NakaLightbox images={data.allFile.edges} delimiter=" -- " />
    </div>
  </Layout>
)

WrightsonFrankensteinPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WrightsonFrankensteinPage

export const pageQuery = graphql`
  query WrightsonFrankensteinPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/wrightson/frankenstein/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
